<template>
  <div class="header" v-show="isShow">
    <div class="header-center">
      <div class="logo">
        <a href="#" class="logo-img" title="www.techscience.com">
          <img :src="imgUrl" alt="" />
        </a>
      </div>
      <!-- <div class="header-center">
        <div class="nav-list">
          <div class="nav-item">Home</div>
          <div class="nav-item">About</div>
          <div class="nav-item">Journals</div>
          <div class="nav-item">Policies</div>
          <div class="nav-item">Support Center</div>
          <div class="nav-item">Partners</div>
          <div class="nav-item">News</div>
        </div>
      </div> -->
      <div class="header-right">
        <div class="icon-search" @click="toSearch">
          <i class="iconfont icon-sousuo"></i>
        </div>
        <a
          class="submit"
          target="_blank"
          href="https://ojs.acad-pub.com/index.php/index/login"
        >
          submit</a
        >
        <div>
          <a
            class="user-style"
            target="_blank"
            href="https://ojs.acad-pub.com/index.php/index/login"
          >
            <img src="@/assets/opimg/r1.png" alt="" />
            LOGIN
          </a>
          <a
            class="user-style"
            target="_blank"
            href="https://ojs.acad-pub.com/index.php/index/user/register"
          >
            <img src="@/assets/opimg/r2.png" alt="" />
            REGISTER
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "@/utils/http";
export default {
  data() {
    return {
      imgUrl: "",
      isShow: true,
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    toSearch() {
      this.$router.push({
        path: "/advancedSearch",
      });
    },
    getInfo() {
      get("qq/api/v1/site").then((res) => {
        this.imgUrl = res.data.logo.url;
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "@/assets/iconfont/iconfont.css";
.header {
  width: 100%;
  background-color: #ffffff;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;

  .header-center {
    width: 1400px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      .logo-img {
        height: 80px;
        img {
          height: 80px;
          width: auto;
          transition: all 0.5s;
        }
      }
    }
    .header-right {
      display: flex;
      align-items: center;
      .icon-search {
        cursor: pointer;
        .iconfont {
          color: #255d42;
          font-size: 23px;
          font-weight: bold;
        }
      }
      .submit {
        margin: 0 25px;
        background: #a40000;
        color: #fff;
        line-height: 40px;
        border-radius: 20px;
        width: 100px;
        text-align: center;
        cursor: pointer;
      }
      .user-style {
        font-size: 14px;
        line-height: 25px;
        cursor: pointer;
        display: block;
      }
    }
  }
}
</style>
