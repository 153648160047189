<template>
  <div class="home">
    <div class="centerHome">
      <div class="banner">
        <div class="bannerText">
          <div class="bannerTitle">Academic publishing</div>
          <div class="bannerDesc">
            Academic exchange and publishing, comprehensive management based on
            science and technology, and exploration of international publishing
            resources.
          </div>
        </div>
      </div>
    </div>
    <div class="search">
      <div class="centerHome">
        <h1 style="text-align: center">Search</h1>
        <div class="search-box">
          <div style="margin-top: 15px">
            <el-input
              placeholder="Please enter the content"
              v-model="input"
              class="input-with-select"
            >
              <el-select
                v-model="select"
                slot="prepend"
                placeholder="Please select"
              >
                <el-option label="Journal Title" value="1"></el-option>
                <el-option label="Article Title" value="2"></el-option>
                <el-option label="keyword" value="3"></el-option>
              </el-select>
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="handleSearch"
              ></el-button>
            </el-input>
          </div>
        </div>
      </div>
    </div>

    <div class="centerHome">
      <div class="journalList">
        <h1 class="title">Journals</h1>
        <div class="journalBox">
          <div
            class="journalItem"
            v-for="(item, index) in journalList"
            :key="index"
          >
            <div class="journalImg">
              <img :src="item.thumbnail.url" alt="" />
            </div>
            <div class="journalText">
              <h2 class="journalTitle" @click="toDetailPages(item)">
                {{ item.title }}
              </h2>
              <p class="journalDesc" v-show="item.onlineIssn">
                ISSN: {{ item.onlineIssn }}
              </p>
              <p class="journalDesc" v-show="item.printIssn">
                ISSN: {{ item.printIssn }}
              </p>
              <p class="journalDesc" v-html="item.publicationFrequency"></p>
              <div class="other">
                <p class="journalDesc" v-show="item.citescore">
                  Citescore: {{ item.citescore }}
                </p>
                <p class="journalDesc" v-show="item.frequency">
                  Impact Factor: {{ item.frequency }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="more">
          <span @click="toJournalList">
            more journals <i class="el-icon-d-arrow-right"></i>
          </span>
        </div>
      </div>
      <div class="articleList">
        <h1 class="title">Latest Articles</h1>
        <div class="articleBox">
          <!-- @click="toArticleDetail(item)" -->
          <div
            class="articleItem"
            v-for="(item, index) in articleList"
            :key="index"
            @click="toArticleDetail(item)"
          >
            <div class="journalName">{{ item.journalTitle }}</div>
            <div class="onlineTime">
              <i class="el-icon-date"></i>{{ item.datePublished }}
            </div>
            <div class="articleTitle" style="text-align: left">
              {{ item.fullTitle }}
              <!-- Finite element analysis of the biomechanical effects of anterior
              and posterior cervical fusion surgery for bilateral cervical
              dislocation -->
            </div>
            <div class="articleType">{{ item.section }}</div>
            <div class="doi">{{ item.doi }}</div>
          </div>
        </div>
        <div class="more">
          <span @click="toJournalList">
            more journals <i class="el-icon-d-arrow-right"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "@/utils/http";
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      input: "",
      select: "1",
      journalList: [],
      articleList: [],
      loading: false,
    };
  },
  mounted() {
    this.getJournalList();
    this.getArticlesList();
  },
  methods: {
    getJournalList() {
      this.loading = true;
      get("index/api/v1/journals?count=6&offset=0")
        .then((res) => {
          if (res.code == 200) {
            this.journalList = res.data.items;
          } else {
            this.$message.console.error(res.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getArticlesList() {
      this.loading = true;
      get("/index/api/v1/submissions/allLatest?count=8&offset=0")
        .then((res) => {
          if (res.code == 200) {
            this.articleList = res.data.items;
          } else {
            this.$message.console.error(res.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    toJournalList() {
      this.$router.push({
        path: "/journalList",
      });
    },
    toDetailPages(item) {
      //id存储到本地
      sessionStorage.setItem("journal", JSON.stringify(item.path));
      this.$router.push({
        path: "/detailPages/" + item.path,
        query: {
          journal: item.path,
        },
      });
    },
    toArticleDetail(item) {
      //id存储到本地
      this.$router.push({
        path: "/detailPages/" + item.journalPath + "/articalDetail",
        query: {
          journal: item.journalPath,
          id: item.id,
        },
      });
    },
    handleSearch() {
      if (this.select == "1") {
        this.$router.push({
          name: "journalList",
          query: {
            searchCriteria: this.input,
          },
        });
      } else {
        this.$router.push({
          name: "advancedSearch",
          query: {
            searchCriteria: this.input,
            type: this.select,
          },
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import url("@/assets/css/commen.css");
.home {
  background-color: #ffffff;
  .centerHome {
    width: 98%;
    max-width: 1400px;
    margin: auto;
    padding: 30px 0;
    .banner {
      width: 1400px;
      height: 350px;
      background: url("@/assets/images/banner.png") #ffffff no-repeat center;
      box-shadow: #ececec 0px 0px 10px;
      position: relative;
      .bannerText {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .bannerTitle {
          font-size: 26px;
          font-weight: bold;
          margin-bottom: 10px;
          line-height: 38px;
          width: auto;
          // border-bottom: 2px double #585858;
        }
        .bannerDesc {
          font-size: 18px;
          line-height: 30px;
          color: #585858;
        }
      }
    }
    .journalList {
      .journalBox {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        .journalItem {
          width: calc(33.3% - 30px);
          margin: 0 15px 20px;
          background: #ffffff;
          box-shadow: #ececec 0px 0px 10px;
          padding: 20px;
          display: flex;
          justify-content: space-between;
          border-radius: 3px;
          border: 1px solid #ffffff;
          box-sizing: border-box;
          transition: all 0.6s;
          .journalImg {
            width: 35%;
            height: auto;
            overflow: hidden;
            flex-shrink: 0;

            img {
              width: 100%;
              height: auto;
            }
          }
          .journalText {
            width: calc(65% - 10px);
            padding: 5px 0 0 10px;
            color: #333;
            position: relative;
            .journalTitle {
              margin-bottom: 6px;
              cursor: pointer;
            }
            .journalDesc {
              line-height: 20px;
              font-size: 14px;
              color: #585858;
            }
            .other {
              position: absolute;
              // left: 0;
              bottom: 0px;
            }
          }
        }
        .journalItem:hover {
          transition: all 0.6s;
          border: 1px solid #f19600;
          // box-sizing: border-box;
          .journalText {
            .journalTitle {
              color: #f19600;
            }
          }
        }
      }
      .more {
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
        margin-top: 15px;
        color: #666;
        cursor: pointer;
        transition: all 0.6s;
        // 旋转90°
        i {
          transform: rotate(90deg);
        }
      }
      .more:hover {
        margin-top: 20px;
        transition: all 0.6s;
        color: #a40000;
      }
    }
    .articleList {
      .articleBox {
        display: flex;
        flex-wrap: wrap;
        .articleItem {
          transition: all 0.6s;
          width: calc(25% - 30px);
          padding: 20px;
          box-sizing: border-box;
          margin: 15px;
          border: 1px solid #ececec;
          .journalName {
            font-size: 14px;
            color: #0f7497;
            margin-bottom: 5px;
          }
          .onlineTime {
            color: #cccccc;
            font-size: 14px;
            margin-bottom: 5px;
            i {
              margin-right: 5px;
            }
          }
          .articleTitle {
            font-size: 16px;
            font-weight: bold;
            justify-content: space-between;
            line-height: 26px;
            margin-bottom: 5px;
            cursor: pointer;
            height: 100px;
            overflow: hidden;
            // text-align: justify;
          }
          .articleType {
            font-size: 14px;
            color: #ffffff;
            padding: 3px 8px;
            border-radius: 5px;
            background: #f19600;
            display: inline-block;
            margin-bottom: 5px;
          }
          .doi {
            color: #585858;
            font-size: 14px;
          }
        }
        .articleItem:hover {
          // border: 1px solid #f19600;
          box-shadow: #585858 0px 0px 10px;
          transition: all 0.6s;
          .articleTitle {
            color: #f19600;
            text-decoration: underline;
          }
        }
      }
      .more {
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
        margin-top: 15px;
        color: #666;
        cursor: pointer;
        transition: all 0.6s;
        // 旋转90°
        i {
          transform: rotate(90deg);
        }
      }
      .more:hover {
        margin-top: 20px;
        transition: all 0.6s;
        color: #a40000;
      }
    }
  }
  .search {
    width: 100%;
    height: auto;
    background: #ececec;
    padding: 30px 0;
    .centerHome {
      width: 100%;
      max-width: 1400px;
      margin: auto;
      .search-box {
        width: 800px;
        margin: auto;
      }
    }
  }
}
</style>
<style scoped>
.el-select {
  width: 130px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>
