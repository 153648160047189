<template>
  <div class="header">
    <div class="header-center">
      <ul class="nav-tabs">
        <li
          v-for="(item, index) in navList"
          :key="index"
          :class="{ select: item.path == $route.path }"
        >
          <router-link :to="item.path">{{ item.name }}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { post } from "@/utils/http";
export default {
  data() {
    return {
      navList: [
        { name: "Home", path: "/" },
        { name: "Journals", path: "/journalList" },
        {
          name: "Editorial and Publishing Policies",
          path: "/editorialPublishing",
        },
        // { name: "Partners", path: "/" },
        { name: "News & Announcements", path: "/announcementsList" },
        { name: "About", path: "/aboutView" },
      ],
    };
  },
  methods: {
    getList() {
      post(
        "/index/api/v1/stats/sitePage?path=" + window.location.pathname
      ).then();
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
<style lang="less" scoped>
@import "@/assets/iconfont/iconfont.css";
.header {
  width: 100%;
  background-color: #ffffff;
  height: 50px;
  position: fixed;
  top: 100px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 4px 4px 4px rgba(195, 195, 195, 0.35);
  z-index: 999;
  .header-center {
    width: 1400px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nav-tabs {
      width: 100%;
      display: flex;
      justify-content: space-between;
      li {
        list-style: none;
        cursor: pointer;
        height: 50px;
        font-size: 16px;
        line-height: 50px;
        position: relative;
        font-weight: bold;
        font-family: "Arial", "Helvetica", sans-serif; /* 设置字体为黑体 */
        // .line {
        //   width: 100%;
        //   height: 2px;
        //   background-color: #a40000;
        //   position: absolute;
        //   top: 0;
        //   left: 50%;
        //   transform: translateX(-50%);
        // }
      }
      li:after {
        content: "";
        width: 0;
        height: 3px;
        background-color: #a40000;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.5s;
      }
      li:hover:after {
        width: 100%;
      }
      li:hover {
        color: #a40000;
      }
      .select {
        color: #a40000;
      }
      .select:after {
        width: 100%;
      }
    }
  }
}
</style>
